<script>
import { PortableText } from '@portabletext/svelte';
export let section;
</script>

<section class="closing-statement-section">
  <div class="closing-statement-section_bg-desktop">
    <picture>
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/0f59f309fb0b043e42aad04717a777ca41032fe4-2957x1484.png?auto=format&w=450"
        media="(min-width: 1800px)"
      />
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/0f59f309fb0b043e42aad04717a777ca41032fe4-2957x1484.png?auto=format&w=450"
        media="(min-width: 768px)"
      />
      <img
        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
        role="presentation"
        class="bg-corner-cover"
        loading="lazy"
        aria-hidden="true"
        alt="Decorative corner element."
      />
    </picture>

    <picture>
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/aff691c5fba101c60f668bf3edc6401bcf4914ea-2000x2000.svg"
        media="(min-width: 768px)"
      />
      <img
        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
        role="presentation"
        alt="Point of view shot of someones hands holding a phone with the Varo app open. "
        class="bg-hands"
        loading="lazy"
      />
    </picture>

    <picture>
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/8232be3dfac71cec681b0dc3771e56e46d10066d-3840x2800.png?auto=format&w=1200"
        media="(min-width: 1000px)"
      />
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/8232be3dfac71cec681b0dc3771e56e46d10066d-3840x2800.png?auto=format&w=800"
        media="(min-width: 768px)"
      />
      <img
        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
        role="presentation"
        aria-hidden="true"
        loading="lazy"
        alt="Decorative frame element."
        class="bg-frame"
      />
    </picture>

    <picture>
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/7d9f9831815b88e652c7f1388085106851247ab8-3840x2182.png?auto=format&w=1800"
        media="(min-width: 1800px)"
      />

      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/7d9f9831815b88e652c7f1388085106851247ab8-3840x2182.png?auto=format&w=1300"
        media="(min-width: 1000px)"
      />
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/7d9f9831815b88e652c7f1388085106851247ab8-3840x2182.png?auto=format&w=800"
        media="(min-width: 768px)"
      />
      <img
        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
        role="presentation"
        loading="lazy"
        alt="A scene of children playing soccer in a remote village."
        class="bg-village"
      />
    </picture>
    <picture>
      <source
        srcset="https://cdn.sanity.io/images/13m8empt/production/e2c2a52fbde9cd2304e7f90bc519f0b5dd38d109-3840x2182.png?auto=format&w=800"
        media="(min-width: 768px)"
      />
      <img
        src="https://cdn.sanity.io/images/13m8empt/production/4848a09d2489a6be5c15bdaf6f44c1f9e0d608ee-1x1.gif"
        role="presentation"
        alt=""
        loading="lazy"
        class="bg-top-frame"
      />
    </picture>
  </div>
  <div class="closing-statement-section__inner">
    <div class="container-fluid">
      <div class="row  center-md">
        <div class="col-sm-12">
          <img
            src="https://cdn.sanity.io/images/13m8empt/production/538915f896177e1aa58b4cc845152f1eac9ce2cb-800x476.jpg?auto=format"
            alt="Point of view shot of someones hands holding a phone with the Varo app open. The background has a scene of children playing soccer in a remote village."
            class="closing-statement-section__mobile-img"
            loading="lazy"
          />
        </div>
        <div class="col-sm-12 col-lg-11">
          <div class="closing-statement-section__copy">
            <h1 class="headline  closing-statement-section__headline">
              {section.headline}
            </h1>
            <div class="closing-statement-section__body">
              <PortableText value={section.body} />
            </div>

            <a
              href={section.link.href}
              title="Download Varo on the Google Play store"
              target="_blank"
              rel="external"
              class="closing-statement-section__button"
            >
              <svg>
                <use xlink:href="#google-play-button" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style>
.closing-statement-section {
  text-align: center;
  min-height: 43rem;
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: 4rem;
  margin-top: -4rem;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section {
    overflow: hidden;
    padding-bottom: 0;
    max-height: 56.25rem;
    height: 100vh;
    max-height: 39rem;
    margin-top: 0;
    min-height: 30rem;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section {
    max-height: 56rem;
    min-height: 56rem;
    margin-top: -8vh;
  }
}
@media only screen and (min-width: 1681px) {
  .closing-statement-section {
    max-height: 94rem;
    height: 105vh;
  }
}
.closing-statement-section .closing-statement-section__inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.closing-statement-section .closing-statement-section__image {
  z-index: -1;
  position: relative;
  height: 24rem;
  top: 2rem;
  width: 100vw;
  left: -4vw;
  margin: auto;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__image {
    position: absolute;
    bottom: 0;
    top: auto;
    right: 0;
    left: 0;
    height: 35rem;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section .closing-statement-section__image {
    height: 59rem;
    bottom: -4rem;
    max-width: 100vw;
  }
}
.closing-statement-section
  .closing-statement-section__image
  .closing-statement-section__image__foreground {
  position: absolute;
  width: 108%;
  right: auto;
  max-width: 49rem;
  left: -4vw;
  left: -12vw;
  bottom: -5vw;
  -o-object-fit: cover;
  object-fit: cover;
  min-width: 33rem;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section
    .closing-statement-section__image
    .closing-statement-section__image__foreground {
    max-width: 66rem;
    left: -28vw;
    bottom: -8rem;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section
    .closing-statement-section__image
    .closing-statement-section__image__foreground {
    bottom: -5rem;
    left: -10vw;
    width: 100%;
    width: 76vw;
    max-width: 76vw;
    min-width: 60rem;
  }
}
@media only screen and (min-width: 1681px) {
  .closing-statement-section
    .closing-statement-section__image
    .closing-statement-section__image__foreground {
    max-width: 82rem;
    left: -32vw;
    bottom: -4rem;
    top: auto;
  }
}
.closing-statement-section
  .closing-statement-section__image
  .closing-statement-section__image__foreground
  img {
  width: 100%;
}
.closing-statement-section
  .closing-statement-section__image
  .closing-statement-section__image__background {
  position: absolute;
  bottom: -1rem;
  z-index: -1;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  left: 0;
  margin: auto;
  top: 0;
  right: 0;
  height: 100%;
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section
    .closing-statement-section__image
    .closing-statement-section__image__background {
    position: absolute;
    bottom: 9rem;
    z-index: -1;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    left: 0;
    margin: auto;
    top: 0;
    right: 0;
    height: 100%;
    -o-object-position: bottom;
    object-position: bottom;
    bottom: 0;
  }
}
.closing-statement-section .closing-statement-section__copy {
  z-index: 1;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__copy {
    top: -25vw;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section .closing-statement-section__copy {
    top: -12vw;
  }
}
.closing-statement-section .closing-statement-section__headline {
  max-width: 20rem;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__headline {
    max-width: 30rem;
    font-size: 2rem;
    line-height: 1.25;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section .closing-statement-section__headline {
    max-width: 32rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 2.15rem;
    line-height: 1.2;
  }
}
.closing-statement-section .closing-statement-section__body {
  max-width: 24rem;
  margin-right: auto;
  margin-left: auto;
}
@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__body {
    max-width: 35rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}
@media only screen and (min-width: 1000px) {
  .closing-statement-section .closing-statement-section__body {
    max-width: 38rem;
    font-size: 1.15rem;
    line-height: 1.5;
  }
}
.closing-statement-section .closing-statement-section__button {
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 11rem;
}
.closing-statement-section .closing-statement-section__button svg {
  width: 11rem;
  display: block;
  height: 4rem;
}

@media only screen and (min-width: 768px) {
  .closing-statement-section {
    max-height: 44rem;
    min-height: 40rem;
  }
}

@media only screen and (min-width: 1000px) {
  .closing-statement-section {
    height: 65rem;
    min-height: 65rem;
    margin-top: -8vh;
  }
}

@media only screen and (min-width: 1000px) {
  .closing-statement-section {
    margin-top: -8vh;
  }
}

.closing-statement-section__mobile-img {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 768px) {
  .closing-statement-section__mobile-img {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .closing-statement-section__copy {
    margin-bottom: 0;
    top: -13rem;
  }
}

@media screen and (min-width: 1000px) {
  .closing-statement-section__copy {
    margin-bottom: 0;
    top: -15rem;
  }
}

.closing-statement-section {
  position: relative;
}

.closing-statement-section_bg-desktop {
  display: none;
}

.closing-statement-section_bg-desktop {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  max-width: 108rem;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.bg-village,
.bg-frame,
.bg-top-frame {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.bg-frame {
  z-index: 1;
  object-fit: contain;
  object-position: bottom;
  width: 100%;
}

@media screen and (min-width: 1681px) {
  .bg-village {
    position: absolute;
    object-fit: cover;
    width: 89%;
    height: 97%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media screen and (min-width: 1000px) {
  .bg-frame {
    object-fit: cover;
  }
}

.closing-statement-section_bg-desktop:before {
  content: ' ';
  background-color: white;
  width: 100%;
  height: 20vw;
  position: absolute;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .closing-statement-section_bg-desktop:before {
    height: 20vw;
  }
}

@media screen and (min-width: 1000px) {
  .closing-statement-section_bg-desktop:before {
    height: 6vw;
    max-height: 10rem;
  }
}

.bg-top-frame {
  object-fit: contain;
  object-position: 0 7rem;
  width: 150vw;
}

@media screen and (min-width: 1000px) {
  .bg-top-frame {
    object-fit: cover;
    object-position: center;
    top: -6rem;
    width: 150vw;
    height: 130%;
  }
}

@media screen and (min-width: 1400px) {
  .bg-top-frame {
    height: 100%;
    top: 0rem;
  }
}

.bg-hands {
  display: none;
}

@media screen and (min-width: 768px) {
  .bg-hands {
    display: block;
    position: absolute;
    bottom: -6rem;
    left: 2rem;
    z-index: 3;
    width: 60%;
    max-width: 32rem;
    height: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .bg-hands {
    max-width: 52rem;
    min-width: 45rem;
    left: 2rem;
    bottom: -9.5rem;
    height: 70%;
    transform: rotate(3deg);
  }
}

@media screen and (min-width: 1681px) {
  .bg-hands {
    left: 8vw;
    height: 82%;
  }
}

@media screen and (min-width: 1800px) {
  .bg-hands {
    transform: rotate(0);
    left: 10vw;
    bottom: -12rem;
  }
}

@media screen and (min-width: 2000px) {
  .bg-hands {
    left: 10rem;
  }
}

.bg-corner-cover {
  display: block;
}

@media screen and (min-width: 768px) {
  .bg-corner-cover {
    position: absolute;
    bottom: 0;
    z-index: 4;
    width: 90%;
    left: 0;
    display: block;
  }
}

@media screen and (min-width: 1681px) {
  .bg-corner-cover {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
    width: 100%;
    object-fit: contain;
  }
}

@media screen and (min-width: 1800px) {
  .bg-corner-cover {
    left: -8rem;
  }
}

.closing-statement-section .closing-statement-section__headline {
  max-width: 23rem;
}

@media only screen and (min-width: 768px) {
  .closing-statement-section .closing-statement-section__headline {
    max-width: 27rem;
  }
}
</style>
